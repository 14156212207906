import { ReCaptchaProvider } from "next-recaptcha-v3";
import GoogleAnalytics from "@bradgarropy/next-google-analytics";
import App from 'next/app';
import { fetchGetCompanyGeneralData, fetchGetCompanySEO, fetchInsertPageView } from "../../services/api";
import { FacebookPixel } from "../components/default/facebook-pixel";

function MyApp({ Component, pageProps, seo, companyGeneralData }) {
  return <ReCaptchaProvider reCaptchaKey={JSON.parse(companyGeneralData).company.captchaPublicKey}>
    {
      seo && seo.googleAnalyticsId
        ? <GoogleAnalytics measurementId={seo.googleAnalyticsId} />
        : null
    }
    {
      seo && seo.facebookPixelId
        ? <FacebookPixel facebookPixelId={seo.facebookPixelId} />
        : null
    }
    <Component {...pageProps} companyGeneralData={companyGeneralData} />
  </ReCaptchaProvider>
}

MyApp.getInitialProps = async (context) => {
  const pageProps = await App.getInitialProps(context);
  const seo = await fetchGetCompanySEO();
  const companyGeneralData = await fetchGetCompanyGeneralData();
  fetchInsertPageView({ page: context.router.asPath }).catch(err => { console.log(err.toString()) });

  return { ...pageProps, seo, companyGeneralData };
};

export default MyApp
